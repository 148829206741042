import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image";

import Layout from "../components/layout"
import SEO from "../components/seo"

import Contact from "../../content/pages/contact.yaml"

const ContactPage = ({ data, location }) => {
  return (
    <Layout
      location={location}
      // menuBackgroundImage={getSrc("../sew-a-scrunchie-1.jpg")}
    >
      <SEO title={Contact.title} description={Contact.metaDescription} />
      <h1 className="font-semibold text-gray-900 text-4xl">Hello!</h1>
      <div className="mt-8 max-w-screen-sm">
        <p>
          If you would like to get in touch with me, write a direct message to
          my Instagram account,{" "}
          <a
            href="https://www.instagram.com/emilia.sewing"
            target="_blank"
            rel="noopener"
          >
            @emilia.sewing
          </a>
          .
          <br />I will do my best to reply as soon as possible!
        </p>

        <p className="mt-8">
          To stay in the loop and be the first to know when I post new content
          on my blog, drop your email below!
          <br />I promise to take good care of it.
        </p>
        <form
          className="mt-4 sm:w-2/3 flex flex-col"
          action="https://fashion.us4.list-manage.com/subscribe/post"
          method="post"
          noValidate
        >
          <input type="hidden" name="u" value="fc235d264b20facd93a410b5c" />
          <input type="hidden" name="id" value="0f5854635d" />
          <label htmlFor="mce-FNAME" className="text-sm">
            What's your name?
          </label>
          <input
            className="mt-1 block p-2 rounded-sm bg-red-100 placeholder-gray-800"
            id="mce-FNAME"
            type="text"
            placeholder="Samantha"
            name="FNAME"
          />
          <label htmlFor="mce-EMAIL" className="mt-2 text-sm">
            and your email address?
          </label>
          <input
            className="mt-1 block p-2 rounded-sm bg-red-100 placeholder-gray-800"
            id="mce-EMAIL"
            type="email"
            placeholder="seamstress@creative.com"
            name="EMAIL"
            required
          />
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_fc235d264b20facd93a410b5c_0f5854635d"
              tabIndex={-1}
              value=""
            />
          </div>
          <button
            className="self-start mt-4 py-2 px-3 rounded border-2 border-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
            type="submit"
            value="Subscribe"
          >
            Subscribe
          </button>
        </form>
      </div>
    </Layout>
  );
}

export default ContactPage

export const query = graphql`query ContactPageQuery {
  menuBackgroundImage: file(relativePath: {regex: "/sew-a-scrunchie-1.jpg/"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, placeholder: BLURRED, layout: FULL_WIDTH)
    }
  }
}
`
